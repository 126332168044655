

.header{
    width: 100%;
    border-bottom: 1px solid #797474;
    padding: 10px;
    background-color: #fff;
    .heder__top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .heder__top__logo{
            width: 100px;
            height: 100px;
            border-radius: 10px;
            border: 2px solid #337ab7;
            box-shadow: 0px 0px 5px #7b7878;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
        .heder__top__info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 20px;
            .heder__top__info__item{
                display: flex;
                align-items: center;
                .item__call__img{
                    width: 40px;
                    height: 40px;
                    margin-right: 15px;
                }
            }
        }
        @media (max-width:938px){
            display: none;
        }
    }
    .header__bottom{
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            @media (max-width:938px){
                flex-direction: column-reverse;
                row-gap: 20px;
                padding-bottom: 10px;
            }
            @media (max-width:580px){
                padding-bottom: 0px;
            }
        .header__bottom__scroll{
            nav{
                ul{
                    display: flex;
                    column-gap: 10px;
                    align-items: center;
                    @media (max-width:746px){
                        display: grid;
                        grid-template-columns: auto auto auto;
                        grid-column-gap: 5px;
                        grid-row-gap: 30px;
                        justify-items: center;
                    }
                    li{
                        transition: .3s ease-in-out;
                        a{
                            font-size: 18px;
                            padding: 10px 15px;
                            border: 2px solid rgba(0, 0, 0, 0);
                            border-radius: 10px;
                            transition: .3s ease-in-out;
                            background-color: #337ab7;
                            color: #fff;
                            box-shadow: 2px 2px 2px #000;
                            @media (max-width:580px){
                                font-size: 16px;
                                padding: 5px;
                            }
                        }:hover{
                            background-color: #fff;
                            border: 2px solid #337ab7;
                            color: #000;
                            box-shadow: 2px 2px 0px #fff;
                            
                        }
                        
                    }:hover{
                        transform: translate(3px, 3px);
                    }
                }
            }
        }
    @media (max-width:938px){
        margin: 0 0 0 0;
    }
}
.header__bottom__btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width:938px){
        width: 100%;
    }
    img{
        display: none;
        width: 100px;
        height: 100px;
        border-radius: 10px;
        border: 2px solid #337ab7;
        box-shadow: 0px 0px 5px #7b7878;
        @media (max-width:938px){
            display: block;
        }
        @media (max-width:330px){
            width: 80px;
            height: 80px;
        }
    }
    a{
        display: block;
    }
}
}
.header__bottom__link{
    color: #fff;
    border: none;
    animation-name: button__popUp;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
@keyframes button__popUp{
    from{
        background-color: #d60505;
        scale: 0.9;
    }
    to{
        background-color: #bb0606;
        scale: 1;
    }
}