
.modal{
    height: 100vh;
    width: 100vw;
    background-color: rgba($color: #000000, $alpha: .4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 100;
}
.modal.active{
    opacity: 1;
    pointer-events: all;
}
.modal__content {
    padding: 40px;
    border-radius: 12px;
    background-color: #fff;
    transform: scale(.5);
    transition: .4s all;
    width: 580px;
    @media (max-width:580px){
        padding: 20px;
        width: 100%;
    }
}
.modal__content.active{
    transform: scale(1);
}
form h2{
    font-size: 50px;
    text-align: center;
    margin-bottom: 20px;
    @media (max-width:500px){
        font-size: 35px;
    }
}
.form__modal{
    display: flex;
    align-items: center;
    margin: 0 auto 40px;
    img{
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    input{
        height: 80px;
        font-size: 30px;
        border-bottom: 1px solid #000000;
        width: 100%;
        @media (max-width:500px){
            font-size: 20px;
        }
    }
    select{
        font-size: 20px;
    }
}
.modal__submit{
    text-align: center;
    padding: 10px 15px;
    background-color: #bb0606;
    color: #fff;
    border-radius: 10px;
    font-size: 30px;
}