

// ********************************

.amenities__subtitle{
    font-size: 20px;
    line-height: 144%;
    margin-bottom: 40px;
    
}
.amenities__grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
    justify-items: center;
    @media (max-width:1300px){
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width:840px){
        grid-template-columns: 1fr;
    }
}

