

.price__item{
    margin-top: 50px;
    display: grid;
    grid-template-columns: 320px 320px 320px;
    column-gap: 20px;
    row-gap: 50px;
    color: #fff;
    justify-content: center;
    height: 100%;
    font-family: serif;
    @media (max-width:1010px){
        grid-template-columns: 320px 320px;
    }
    @media (max-width:675px){
        grid-template-columns: 320px;
    }
    @media (max-width:330px){
        grid-template-columns: 1fr;
    }
}
.price__item__cart__images{
    height: 90px;
    .price__item__cart__images_img1{
        margin-top: -60px;
        height: 120px;
    }
    .price__item__cart__images_img2{
        margin-left: 20%;
        @media (max-width:330px){
            margin-left: 10%;
        }
    }
}
.price__item__cart{
    padding: 15px;
    background-color: #003458;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    
    h2{
        margin-top: 30px;
        font-size: 24px;
    }
    h4{
        margin: 20px 0;
    }
    
}
.price__item__cart__btn{
    margin: 20px;
    a{
        z-index: 30;
        position: relative;
        color: #fff;
        padding: 15px 15px;
        &::after{
            content: "";
            position: absolute;
            background-image: url(../../asset/price/arrow.svg);
            background-repeat: no-repeat;
            right: -75px;
            top: 20px;
            width: 40px;
            height: 40px;
            color: #fff;
            transition: .3s ease-in-out;
        }
        &::before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 64px;
            height: 100%;
            border-radius: 10px;
            background-color: #cd0921;
            transition: .3s ease-in-out;
            z-index: -1;
            
        }
    }
}
.price__item__cart:hover a::before{
    width: 100%;
}
.price__item__cart:hover a::after{
    transform: translateX(5px) scale(1.1);
}








//***************** table****************
// .price__item__table{
//     border: 3px solid #628790;
//     border-collapse: collapse;
//     margin: 0 auto;
//     width: 100%;
//     th{
//         text-transform: uppercase;
//         border: 3px solid #628790;
//         height: 50px;
//         background-color: #8e0087;
//         color: #fff;
//     }
//     td{
//         border: 3px solid #628790;
//         height: 30px;
//         padding: 20px;
//     }
// }