
// Шрифт
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@300;400;700&family=Jost&display=swap');
// font-family: 'Encode Sans Condensed', sans-serif;
// font-family: 'Jost', sans-serif;
// *****************************************


.primary{
    margin-top: 20px;
    position: relative;
    height: 550px;
    .primary__text{
        z-index: 100;
        width: 600px;
        height: 200px;
        color: #fefefe;
        font-family: 'Encode Sans Condensed';
        padding: 40px 20px;
        h1{
            font-size: 60px;
            font-weight: 700;
            text-shadow: 2px 2px 4px #000000;
            @media (max-width:600px){
                font-size: 40px;
            }
        }
        h2{
            font-size: 30px;
            @media (max-width:600px){
                font-size: 20px;
            }
        }
        .primary__text__button{
            margin-top: 20px;
            display: inline-block;
            text-align: center;
            a{
                margin-right: 10px;
                transition: .3s;
            }:hover{
                scale: 1.05;
            }
            .primary__text__button_btn1{
                background-color: #bb0606;
                margin-bottom: 15px;
            }
            .primary__text__button_btn2{
                text-align: center;
            }
            @media (max-width:433px){
                flex-direction: column;
                row-gap: 15px;
                align-items: start;
            }
        }
        @media (max-width:600px){
            width: 100%;
        }
    }
    .primary__images{
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        span{
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(to right, #628790 200px, rgba($color: #000000, $alpha: .0));
            width: 80%;
            z-index: 100;
            height: 100%;
            opacity: 0.9;
            @media (max-width:580px){
                width: 100%;
            }
        }
        @media (max-width:1300px){
            height: 550px;
        }
        @media (max-width:580px){
            width: 100%;
        }
    }
    @media (max-width:600px){
        width: 100%;
    }
}
// ****************************
// services
.services{
    margin-top: 50px;
    .services__grid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 15px;
        row-gap: 15px;
        justify-items: center;
        @media (max-width:1300px){
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width:847px){
            grid-template-columns: 1fr;
        }
        @media (max-width:420px){
            
        }
    }
}
// *****************************
// documents
.documents{
    margin-top: 50px;
    .documents__images{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        h6{
            width: 350px;
            height: 500px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .documents__button{
        display: inline-block;
        background-color: #bb0606;
        margin-top: 30px;
        transition: .3s ease-in;
        &:hover{
            scale: 1.03;
        }
    }
}
// klientam****************
.klientam{
    .klientam__images{
        text-align: center;
    }
}
// about********************
.about{
    p{
        line-height: 145%;
    }
}
// call-wizard*************
.call-wizard{
    p{
        line-height: 145%;
    }
    h5{
        margin: 20px 0;
        font-size: 25px;
    }
    ul{
        margin: 0 0 20px 20px;
        li{
            list-style-type: square;
            line-height: 145%;
        }
    }
}

