


.swiperDocuments__item{
    width: 580px;
    height: 440px;
    margin: 20px auto;
    padding: 20px 0;
    padding: 20px 10px;
    box-shadow: 0 0 15px rgba(213,213,213,.8);
    transition: .2s ease;
    @media (max-width:608px){
        width: 100%;
        height: 380px;
    }
    .swiperDocuments__item__img{
        display: block;
        width: 570px;
        height: 380px;
        margin: 0 auto;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        @media (max-width:608px){
            width: 100%;
            height: 300px;
        }
    }
    h3{
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #000;
        text-transform: uppercase;
    }
}
