

.compani__subtitle{
    line-height: 144%;
    &:not(:last-child){
        margin-bottom: 20px;
    }
}
.compani__title{
    color: red;
}