

.footer{
    margin-top: 50px;
    .footer__top{
        border-top: 1px solid #c6c4c4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;
        .footer__top__logo{
            width: 150px;
            height: 150px;
            border-radius: 10px;
            border: 2px solid #337ab7;
            box-shadow: 0px 0px 5px #7b7878;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
        .footer__top__schedule{
            :not(:last-child){
                margin-bottom: 10px;
            }
        }
        .footer__top__address a{
            display: block;
            margin-bottom: 10px;
            background-color: #ebe7e7;
            background-color: #628790;
            padding: 10px;
            border-radius: 10px;
            color: #fff;
            transition: .3s ease-in-out;
            &:hover{
                opacity: .7;
            }
        }
        @media (max-width:851px){
            flex-direction: column;
            row-gap: 20px;
        }
    }
    .footer__bottom{
        margin: 30px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footer__bottom__info{
            h5{
                font-size: 16px;
            }
            h6{
                font-size: 12px;
            }
        }
        .footer__bottom__social{
            img{
                width: 50px;
                height: 50px;
                margin-right: 10px;
                transition: .2s ease;
                &:hover{
                    scale: 1.1;
                }
            }
        }
        @media (max-width:851px){
            flex-direction: column-reverse;
            row-gap: 20px;
        }
    }
.footer__button{
    background-color: #bb0606;
    transition: .3s all;
    &:hover{
        scale: 1.05;
    }
}

}