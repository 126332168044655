

.contact__information{
    display: flex;
    align-items: center;
    transition: .3s ease;
    span{
        display: block;
        margin-left: 20px;
    }
    a{
        display: block;
        margin-left: 20px;
    }
    img{
        width: 50px;
        height: 50px;
    }
    &:not(:last-child){
        margin-bottom: 20px;
    }
    &:hover{
        transform: translateX(5px);
    }
}
.contact__social{
    margin-top: 30px;
    img{
        transition: .3s ease-in-out;
    }
    :not(:last-child){
        margin-right: 15px;
    }
    :hover{
        scale: 1.2;
    }
}

// .contact__adres{
//     display: flex;
//     align-items: center;
//     span{
//         display: block;
//         margin-left: 20px;
//     }
//     img{
//         width: 50px;
//         height: 50px;
//     }
// }
// .contact__phone{
//     display: flex;
//     align-items: center;
//     span{
//         margin-left: 20px;
//         display: block;
//     }
//     img{
//         width: 50px;
//         height: 50px;
//     }
// }
// .contact__workTime{
//     display: flex;
//     align-items: center;
//     span{
//         margin-left: 20px;
//         display: block;
//     }
//     img{
//         width: 50px;
//         height: 50px;
//     }
// }