

.arschin{
    p{
        margin-bottom: 20px;
    }
    h4{
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    h5{
        font-weight: 600;
    }
    ol{
        margin-left: 20px;
    }
}
.arschin__images{
    margin: 20px 0;
    text-align: center;
}
.arschin__info{
    margin: 20px 0;
    text-align: center;
    img{
        margin-bottom: 10px;
    }
}