

.application{
    form{
        display: flex;
        align-items: center;
        justify-content: space-around;
        .application__form{
            border: 1px solid rgba($color: #000000, $alpha: 0.3);
            display: flex;
            align-items: center;
            padding: 10px 5px;
            border-radius: 10px;
            input{
                display: block;
                font-size: 25px;
                @media (max-width:445px){
                    font-size: 16px;
                    width: 100%;
                }
            }
            img{
                display: block;
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }
        }
        .application__submit{
            background-color: #cd0921;
            padding: 15px 20px;
            border-radius: 10px;
            color: #fff;
            text-align: center;
        }

        @media (max-width:1270px){
            flex-wrap: wrap;
            row-gap: 15px;
        }
        @media (max-width:1115px){
            flex-wrap: nowrap;
            display: grid;

        }
    }
}