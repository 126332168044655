

.servicesItem{
    width: 400px;
    padding: 10px 10px 30px;
    box-shadow: 0px 0px 10px #597cad;
    transition: .3s ease-in-out;
    @media (max-width:420px){
        width: 100%;
    }
    h2{
        margin: 20px 0px;
        font-size: 25px;
    }
    .servicesItem__images{
        width: 380px;
        height: 285px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media (max-width:420px){
            width: 100%;
    }
    }
    button{
        transition: .3s ease-in-out;
        a{
            padding: 10px 15px;
            background-color: #8e0087;
            border-radius: 10px;
            color: #fff;
            box-shadow: 2px 2px 5px #000;
            
        }&:hover{
            box-shadow: 2px 2px 5px #ffffff;
            transform: translate(3px, 3px) scale(.99);
        }
    }
    &:hover{
        box-shadow: 0px 0px 15px #000;
    }
}